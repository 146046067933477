<template>
  <div class="page-wrapper-table-body">
    <div class="page-wrapper-table-body-inner">
      <el-table
        class="align-items-center table-flush"
        header-row-class-name="thead-light"
        :data="charges"
      >
        <el-table-column :label="$t('COMMON.NAME')" prop="name" min-width="350">
          <template v-slot="{ row }">
            <span>
              {{ row.name ?? "" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('CHARGES.COUT')" min-width="450">
          <template v-slot="{ row }">
            <span v-if="row.charge_type == typeMontantFixe">{{
              "$" + row.amount
            }}</span>
            <span v-else-if="row.charge_type == typePourcentage">{{
              row.percentage + "%"
            }}</span>
            <div v-else-if="row.charge_type == typePalier && row.tiers">
              <tiers-list
                :tiers="row.tiers"
                :title="$t('CHARGES.PALIERS')"
                currency="$"
              />
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :label="$t('CHARGES.CREATED_AT')"
          prop="created_at"
          min-width="220"
        >
          <template v-slot="{ row }">
            {{ $formatDate(row.created_at) }}
          </template>
        </el-table-column> -->
        <el-table-column fixed="right" min-width="120">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip
              :content="$t('COMMON.VIEW')"
              placement="top"
              v-if="$currentUserCan($permissions.PERM_VIEW_CHARGES)"
            >
              <a
                type="text"
                @click="viewCharge(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fal fa-expand-alt"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  TYPE_PALIER,
  TYPE_POURCENTAGE,
  TYPE_MONTANT_FIXE,
  CATEGORY_COMMISSION,
} from "@/constants/charges";
import { QUERY_ACTIONS_VIEW } from "@/constants/common";
import { Table, TableColumn } from "element-ui";
import TiersList from "./TiersList.vue";
export default {
  name: "ChargesViewTable",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    TiersList,
  },
  props: {
    charges: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      typeMontantFixe: TYPE_MONTANT_FIXE,
      typePourcentage: TYPE_POURCENTAGE,
      typePalier: TYPE_PALIER,
    };
  },
  methods: {
    viewCharge(charge) {
      this.$router.push({
        name: "List Charges",
        query: { id: charge.id, action: QUERY_ACTIONS_VIEW },
      });
    },
  },
};
</script>

<style scoped>
.paliers {
  margin-top: 20px;
}
</style>
