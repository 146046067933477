<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("CHARGE_PACKAGES.CHARGE_PACKAGES_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button btn-black add"
              icon
              size="sm"
              @click="openChargeList"
              v-if="$currentUserCan($permissions.PERM_VIEW_CHARGE_PACKAGES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-list"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("CHARGES.CHARGES")
              }}</span>
            </base-button>
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openChargePackageCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_CHARGE_PACKAGES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("CHARGE_PACKAGES.ADD_CHARGE_PACKAGE")
              }}</span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_CHARGE_PACKAGES)"
              :objectType="'chargePackages'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <charge-package-list-table
        @onViewChargePackage="openChargePackageViewModal"
        @onEditChargePackage="openChargePackageEditModal"
        @onDeleteChargePackage="deleteChargePackage"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewChargePackageModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewChargePackageModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'CHARGE_PACKAGE_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("CHARGE_PACKAGES.VIEW_CHARGE_PACKAGE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openChargePackage"
                :objectType="'chargePackages'"
                :objectId="openChargePackage.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="edit" @click="openChargePackageEditModal(openChargePackage)">
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button class="delete" @click="deleteChargePackage(openChargePackage)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeChargePackageModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-charge-package-component
              v-if="openChargePackage"
              :chargePackageId="openChargePackage.id"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditChargePackageModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditChargePackageModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'CHARGE_PACKAGE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("CHARGE_PACKAGES.EDIT_CHARGE_PACKAGE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="view" @click="openChargePackageViewModal(openChargePackage)">
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeChargePackageModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-charge-package-component
              v-if="openChargePackage"
              :chargePackageId="openChargePackage.id"
              @onViewChargePackage="openChargePackageViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddChargePackageModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddChargePackageModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'CHARGE_PACKAGE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("CHARGE_PACKAGES.ADD_CHARGE_PACKAGE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeChargePackageModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-charge-package-component @onViewChargePackage="openChargePackageViewModal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import ChargePackageListTable from "./partials/ChargePackageListTable.vue";
import EditChargePackageComponent from "./components/EditChargePackageComponent.vue";
import AddChargePackageComponent from "./components/AddChargePackageComponent.vue";
import ViewChargePackageComponent from "./components/ViewChargePackageComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    ChargePackageListTable,
    NotificationSubscription,
    EditChargePackageComponent,
    AddChargePackageComponent,
    ViewChargePackageComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const chargePackageId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewChargePackageModalOpened = false;
    let isEditChargePackageModalOpened = false;
    let isAddChargePackageModalOpened = false;
    let openChargePackage = null;
    if (chargePackageId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewChargePackageModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditChargePackageModalOpened = true;
      }
      openChargePackage = { id: chargePackageId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddChargePackageModalOpened = true;
    }
    return {
      isViewChargePackageModalOpened: isViewChargePackageModalOpened,
      isEditChargePackageModalOpened: isEditChargePackageModalOpened,
      isAddChargePackageModalOpened: isAddChargePackageModalOpened,
      openChargePackage: openChargePackage,
      renderKey: 1,
    };
  },

  methods: {
    openChargePackageCreateModal() {
      this.closeChargePackageModal();
      this.isAddChargePackageModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ChargePackages",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openChargePackageViewModal(chargePackage, reRender = false) {
      this.closeChargePackageModal();
      this.openChargePackage = chargePackage;
      this.isViewChargePackageModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ChargePackages",
          query: { id: this.openChargePackage.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    openChargeList(){
      this.$router.push({
        name: "List Charges"
      });
    },

    openChargePackageEditModal(chargePackage) {
      this.closeChargePackageModal();
      this.openChargePackage = chargePackage;
      this.isEditChargePackageModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ChargePackages",
          query: { id: this.openChargePackage.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeChargePackageModal() {
      this.isAddChargePackageModalOpened = false;
      this.isViewChargePackageModalOpened = false;
      this.isEditChargePackageModalOpened = false;
      this.openChargePackage = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ChargePackages",
          query: {},
        }).href
      );
    },

    async deleteChargePackage(chargePackage) {
      const confirmation = await swal.fire({
        title: this.$t("CHARGE_PACKAGES.DELETE_THIS_CHARGE_PACKAGE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("chargePackages/destroy", chargePackage.id);
          this.renderKey++;
          this.closeChargePackageModal();
          this.$notify({
            type: "success",
            message: this.$t("CHARGE_PACKAGES.CHARGE_PACKAGE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
