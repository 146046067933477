<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!chargePackage">
      <span class="loader"></span>
    </span>
    <div v-if="chargePackage" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="label"
      >
        <tab-pane title="label" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <charge-package-view-global :chargePackage="chargePackage" @onChargePackageUpdated="get" />
        </tab-pane>
        <tab-pane
          title="charges"
          id="2"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CHARGES)"
        >
          <span slot="title">
            <charge-icon :width="'20px'" class="custom-svg-icon"/>
            {{ $t("CHARGES.CHARGES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <charges-view :chargePackage="chargePackage" @needReload="get"/>
          </div>
        </tab-pane>
        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <charge-package-view-logs :chargePackage="chargePackage" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import ChargePackageViewLogs from "../partials/ChargePackageViewLogs.vue";
import ChargePackageViewGlobal from "../partials/ChargePackageViewGlobal.vue";
import ChargesView from "../partials/charges/ChargesView.vue";
import ChargeIcon from '@/components/Icons/ChargeIcon.vue';

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    ChargePackageViewLogs,
    ChargePackageViewGlobal,
    ChargesView,
    ChargeIcon
  },

  mixins: [],

  props: {
    chargePackageId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      chargePackage: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("chargePackages/get", this.chargePackageId);
        this.chargePackage = this.$store.getters["chargePackages/chargePackage"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
