<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ `${chargePackage.name}` }}</h3>
    <div class="col-12">
      <dl class="row" v-if="chargePackage.name">
        <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
        <dd class="col-sm-8">
          {{ chargePackage.name ?? "" }}
        </dd>
      </dl>
      <dl class="row" v-if="chargePackage.excerpt">
        <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
        <dd class="col-sm-8">
          {{ chargePackage.excerpt ?? "" }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ chargePackage.created_at | moment("LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ chargePackage.updated_at | moment("LLLL") }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  name: "charge-package-view-global",

  props: ["chargePackage"],

  data() {
    return {};
  },

  created() {},

  methods: {
    chargePackageUpdated() {
      this.$emit("chargePackageUpdated", true);
    },
  },

  mounted() {},

  watch: {
    chargePackage(chargePackage) {},
  },
};
</script>
